import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

import AppContextMenu from './AppContextMenu';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    // TODO fix important
    display: 'none !important',
  },
}));

const AppToolbar = ({ showMenu, onToggleMenu }) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <Grid
        container
        direction="row-reverse"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Typography variant="h6" component="span" noWrap>
            VIPER
          </Typography>
          <IconButton
            color="inherit"
            onClick={onToggleMenu}
            edge="end"
            className={clsx(classes.menuButton, showMenu && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item>
          {/* <IconButton color="inherit">
            <Badge
              color="secondary"
              variant="dot"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <NotificationsNoneIcon />
            </Badge>
            </IconButton>*/}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default AppToolbar;

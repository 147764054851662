import { useContext } from 'react';
import AuthContext from '../base/AuthContext';

export const useAuth = () => {
  return useContext(AuthContext);
};

export const useHasRole = (roleNeeded) => {
  const { roles } = useAuth();

  return roles?.includes(roleNeeded);
};

export const useIsAuthenticated = () => {
  const { auth } = useAuth();

  return !!auth;
};

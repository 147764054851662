import React, { useCallback } from 'react';
import AuthContext from './AuthContext';

const KEY = 'token';

const modes = {
  SESSION: {
    read() {
      return sessionStorage.getItem(KEY);
    },
    write(token) {
      sessionStorage.setItem(KEY, token);
    },
  },
  PERSIST: {
    read() {
      return localStorage.getItem(KEY);
    },
    write(token) {
      localStorage.setItem(KEY, token);
    },
  },
  MEMORY: {
    read() {},
    write() {},
  },
};

const AuthProvider = ({ mode = 'SESSION', children }) => {
  // default from token/mode
  // useEffect byMode
  // when mode change useState defaultValue also change?
  // setAuth = () => {
  //   setAuth;
  //   handler[mode]();
  // }
  const [auth, setAuth] = React.useState(null);
  const [roles, setRoles] = React.useState(null);

  const setToken = useCallback(
    (token) => {
      modes[mode].write(token);

      setAuth(token);
    },
    [mode],
  );

  return (
    <AuthContext.Provider value={{
      auth, roles, setToken, setRoles,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

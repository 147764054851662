import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Collapse } from '@material-ui/core';

import { useHasRole } from 'views/auth/hooks/useAuth';
// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

const RouteIcon = ({ icon: Icon }) => {
  const showIcon = !!Icon;

  return (
    showIcon && (
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
    )
  );
};

const SingleRoute = ({
  role, style, url, name, disabled, icon,
}) => {
  const hasRole = useHasRole(role);

  return hasRole ? (
    <ListItem
      disabled={disabled}
      style={style}
      button
      component={AdapterLink}
      to={url}
    >
      <RouteIcon icon={icon} />
      <ListItemText>{name}</ListItemText>
    </ListItem>
  ) : null;
};

const NestedRoute = ({
  url: baseUrl, disabled, name, routes, icon,
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <ListItem disabled={disabled} button onClick={toggleOpen}>
        <RouteIcon icon={icon} />
        <ListItemText>{name}</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open}>
        <List disablePadding>
          {routes.map(({ url, ...rest }) => (
            <SingleRoute
              key={url}
              style={{ paddingRight: 32 }}
              url={`${baseUrl}${url}`}
              {...rest}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const MenuRoute = (props) => {
  const { routes } = props;
  const isNested = !!routes;

  return isNested ? <NestedRoute {...props} /> : <SingleRoute {...props} />;
};

export default MenuRoute;

import React, {
  useState, useCallback, useMemo, memo,
} from 'react';
import ModalContext from './ModalContext';

const ModalRenderer = memo(({ component: Component, data }) => (Component ? Component(data) : null));

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({
    modal: null,
    data: null,
  });

  const showModal = useCallback(
    (newModal, data) => setModal({
      modal: newModal,
      data,
    }),
    [],
  );

  const hideModal = useCallback(
    () => setModal({
      modal: null,
      data: null,
    }),
    [],
  );

  const contextValue = useMemo(() => ({ showModal, hideModal }), [
    showModal,
    hideModal,
  ]);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <ModalRenderer component={modal.modal} data={modal.data} />
    </ModalContext.Provider>
  );
};

export default ModalProvider;

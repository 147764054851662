import React, { useState, useEffect, memo } from 'react';

import clsx from 'clsx';

import SignIn from 'modules/auth/SignIn';
import { useIsAuthenticated } from 'views/auth/hooks';
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';

import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import SidebarMenu from 'components/SidebarMenu';
import blue from '@material-ui/core/colors/blue';
import AppContent from './AppContent';
import AppToolbar from './AppToolbar';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createMuiTheme({
  typography: {
    fontFamily: ['Rubik', 'Roboto', 'sans-serif'],
  },
  direction: 'rtl',
  palette: {
    type: 'light',
    primary: {
      main: '#333',
    },
    secondary: {
      main: blue[600],
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`,
});

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    flexDirection: 'row-reverse',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none !important',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

const useStorageState = (name, defaultState = null) => {
  const [state, setState] = useState(() => {
    const item = localStorage.getItem(name);

    return item ? JSON.parse(item) : defaultState;
  });

  useEffect(() => {
    localStorage.setItem(name, JSON.stringify(state));
  }, [state]);

  return [state, setState];
};

const Main = () => {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();

  const [open, setOpen] = useStorageState('drawer_state');

  const onToggleMenu = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (!isAuthenticated) {
    return <SignIn />;
  }

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <AppToolbar showMenu={open} onToggleMenu={onToggleMenu} />
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <SidebarMenu />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <AppContent />
        </main>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default memo(Main);

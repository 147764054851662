import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation UpdateUser($id: String!) {
    updateUser(id: $id) {
      id
      name
      userName
      roles
      disabled
    }
  }
`;

export const DISABLE_USER = gql`
  mutation DisableUser($id: String!) {
    disableUser(id: $id) {
      id
      name
      userName
      roles
      disabled
    }
  }
`;

export const ADD_USER = gql`
  mutation AddGuarantor($input: NewUserInput!) {
    addUser(input: $input) {
      id
      name
      userName
      roles
    }
  }
`;

export const ADD_GUARANTOR = gql`
  mutation AddGuarantor($clientId: String!, $input: NewGuarantorInput!) {
    addGuarantor(clientId: $clientId, input: $input) {
      id
      pid
      firstName
      lastName
      address
      phone
    }
  }
`;

export const ADD_CLIENT = gql`
  mutation AddClient($input: NewClientInput!) {
    addClient(input: $input) {
      id
      firstName
      lastName
    }
  }
`;

export const NEW_NOTE = gql`
  mutation AddNote($text: String!) {
    addNote(text: $text)
  }
`;

export const ADD_CALCULATOR = gql`
  mutation AddCalculator($input: NewCalculatorInput!) {
    addCalculator(input: $input) {
      id
      name
      type
      ... on FixedTimeCalculator {
        fund
        paymentsCount
        rate
      }
      ... on FixedReturnCalculator {
        fund
        paymentReturn
        rate
      }
      ... on FixedRateCalculator {
        fund
        paymentReturn
        rate
      }
    }
  }
`;

export const ADD_LOAN = gql`
  mutation AddLoan($clientId: String!, $loan: NewLoanInput!) {
    addLoan(clientId: $clientId, loan: $loan) {
      loan {
        id
        status
        rate
        number
        date
        initialFund
        changes
        comment
        notification
        createdAt
        updatedAt
      }
      event {
        id
        type
        date
        comment
      }
    }
  }
`;

export const ADD_EVENT = gql`
  mutation AddEvent($input: NewEventInput!) {
    addEvent(input: $input) {
      loan {
        id
        status
        rate
        initialFund
        changes
        date
        comment
        createdAt
        updatedAt
        meta {
          createdBy {
            name
          }
          updatedBy {
            name
          }
        }
      }
      event {
        id
        type
        date
        comment
        createdAt
        updatedAt
        changes
        meta {
          createdBy {
            name
          }
          updatedBy {
            name
          }
          loan
          client
        }
      }
      account {
        id
        changes {
          fund
          work
          total
          expense
          salary
          general
          discount
        }
      }
    }
  }
`;

export const EDIT_CALCULATOR = gql`
  mutation EditCalculator($id: String!, $name: String!) {
    editCalculator(id: $id, name: $name)
  }
`;

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
      firstName
      lastName
      pid
      address
      phone
      phone2
    }
  }
`;

export const UPDATE_LOAN = gql`
  mutation UpdateNotification(
    $loanId: String!
    $input: UpdateNotificationInput!
  ) {
    updateNotification(loanId: $loanId, input: $input) {
      loan {
        id
        status
        rate
        number
        date
        initialFund
        changes
        comment
        createdAt
        updatedAt
        notification
      }
      event {
        id
        type
        date
        comment
      }
    }
  }
`;

export const DELETE_CALCULATOR = gql`
  mutation DeleteCalculator($id: String!) {
    deleteCalculator(id: $id)
  }
`;

export const DELETE_GUARANTOR = gql`
  mutation DeleteGuarantor($id: String!) {
    deleteGuarantor(id: $id)
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: String!) {
    deleteComment(id: $id) {
      id
    }
  }
`;

export const DELETE_LOAN_HISTORY = gql`
  mutation DeleteLoanHistory($id: ID!) {
    deleteLoanAction(id: $id)
  }
`;

export const DELETE_EVENT = gql`
  mutation DeleteEvent($id: String!) {
    deleteEvent(id: $id) {
      event {
        id
        type
        date
        comment
        createdAt
        updatedAt
        changes
        meta {
          createdBy {
            name
          }
          updatedBy {
            name
          }
          loan
          client
        }
      }
      loan {
        id
        status
        rate
        date
        initialFund
        changes
        comment
        createdAt
        updatedAt
      }
      account {
        id
        changes {
          fund
          work
          general
          expense
          salary
          discount
          total
        }
      }
    }
  }
`;

export const UPDATE_EVENT_COMMENT = gql`
  mutation UpdateEventComment($id: String!, $comment: String!) {
    updateEventComment(id: $id, comment: $comment)
  }
`;

export const ADD_COMMENT = gql`
  mutation AddComment($clientId: String!, $loanId: String, $comment: String!) {
    addComment(clientId: $clientId, loanId: $loanId, comment: $comment) {
      id
      comment
      createdAt
      updatedAt
      meta {
        createdBy {
          userId
          name
        }
      }
    }
  }
`;

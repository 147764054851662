import { gql } from '@apollo/client';

export const CALCULATORS_QUERY = gql`
  {
    calculators {
      id
      name
      type
      createdAt
      updatedAt
      ... on FixedTimeCalculator {
        fund
        paymentsCount
        rate
      }
      ... on FixedReturnCalculator {
        fund
        paymentReturn
        rate
      }
      ... on FixedRateCalculator {
        fund
        paymentReturn
        rate
      }
    }
  }
`;

export const QUERY_NOTIFICATIONS = gql`
  query QueryNotifications($cursor: String, $query: String) {
    notifications(cursor: $cursor, query: $query) {
      hasMore
      cursor
      items {
        id
        number
        notification
        meta {
          updatedBy {
            name
          }
          client {
            clientId
            firstName
            lastName
          }
        }
        updatedAt
      }
    }
  }
`;

export const USERS_QUERY = gql`
  {
    users {
      id
      name
      userName
      roles
      disabled
    }
  }
`;

export const CLIENTS_QUERY = gql`
  {
    clients {
      id
      firstName
      lastName
    }
    overview {
      items
      count
    }
  }
`;

export const GUARANTORS_QUERY = gql`
  query Guarantors($clientId: String!) {
    guarantors(clientId: $clientId) {
      id
      pid
      firstName
      lastName
      address
      phone
    }
  }
`;

export const CLIENT_QUERY = gql`
  query getClient($id: String!) {
    client(id: $id) {
      id
      pid
      firstName
      lastName
      address
      phone
      phone2
    }
    loans(clientId: $id) {
      id
      status
      changes
      rate
      date
      number
      notification
    }
  }
`;

export const CLIENT_LOANS_QUERY = gql`
  query getLoans($clientId: String!) {
    loans(clientId: $clientId) {
      id
      status
      changes
      rate
      date
      number
      notification
    }
  }
`;

export const LOAN_QUERY = gql`
  query getLoan($id: String!) {
    loan(id: $id) {
      id
      status
      rate
      date
      comment
      initialFund
      number
      createdAt
      updatedAt
      changes
      meta {
        createdBy {
          name
        }
        updatedBy {
          name
        }
        client {
          clientId
          firstName
          lastName
        }
      }
      notification
    }
  }
`;

export const QUERY_EVENTS = gql`
  query QueryEvents($cursor: String, $filter: EventFilter!) {
    events(cursor: $cursor, filter: $filter) {
      hasMore
      cursor
      items {
        id
        type
        date
        comment
        changes
        payload
        createdAt
        updatedAt
        meta {
          createdBy {
            name
          }
          updatedBy {
            name
          }
          client
          loan
        }
      }
    }
  }
`;

export const ACCOUNT_QUERY = gql`
  query Account {
    account {
      id
      changes {
        fund
        work
        general
        expense
        salary
        discount
        total
      }
    }
  }
`;

export const MONTHLY_STATS = gql`
  query EventStats($filter: RangeFilter!) {
    eventStats(filter: $filter) {
      work
      fund
      general
      expense
      salary
      discount
    }
  }
`;

export const COMMENTS_QUERY = gql`
  query Comments($clientId: String!, $loanId: String) {
    comments(clientId: $clientId, loanId: $loanId) {
      id
      comment
      createdAt
      updatedAt
      meta {
        createdBy {
          userId
          name
        }
      }
    }
  }
`;

import { Suspense } from 'react';
import Particles from 'react-particles-js';
const randomElement = (items) =>
  items[Math.floor(Math.random() * items.length)];

const SHAPE = randomElement(['edge', 'circle', 'triangle', 'polygon']);
const DIRECTION = randomElement([
  'bottom',
  'top-right',
  'top-left',
  'bottom-right',
  'left',
  'right',
  'bottom-left',
  'top',
  'none',
]);

const SIZE = randomElement([3, 5, 9, 2]);

const COUNT = randomElement([100, 150, 200]);

const BackgroundParticles = () => (
  <Suspense fallback={null}>
    <Particles
      style={{ position: 'absolute', backgroundColor: '#222' }}
      params={{
        particles: {
          color: '#54c8ff',
          shape: {
            type: SHAPE,
          },
          number: {
            value: COUNT,
            density: {
              enable: false,
            },
          },
          size: {
            value: SIZE,
            random: true,
            anim: {
              speed: 9,
              size_min: 0.3,
            },
          },
          line_linked: {
            enable: false,
          },
          move: {
            random: true,
            speed: 4,
            direction: DIRECTION,
            out_mode: 'out',
          },
        },
        interactivity: {
          events: {
            onclick: {
              enable: true,
              mode: 'push',
            },
          },
          modes: {
            bubble: {
              distance: 250,
              duration: 2,
              size: 0,
              opacity: 0,
            },
            repulse: {
              distance: 400,
              duration: 4,
            },
          },
        },
      }}
    />
  </Suspense>
);

export default BackgroundParticles;

import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';

const PageNotFound = () => {
  const location = useLocation();

  return (
    <Grid container direction="column" xs={4} spacing={4}>
      <Grid item>
        <Alert severity="info">
          <AlertTitle>דף זה אינו קיים</AlertTitle>
          <strong>{location.pathname}</strong>
          <br />
          עמוד זה אינו קיים, נסא לנווט בעזרת התפריט
        </Alert>
      </Grid>
      <Grid item>
        <Link to="/">
          <Button variant="outlined" color="secondary">
            חזור לדף הבית
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;

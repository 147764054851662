import { useContext, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import AuthContext from '../base/AuthContext';

export const useLogout = () => {
  const client = useApolloClient();
  const { setToken, setRoles } = useContext(AuthContext);

  return useCallback(() => {
    client.clearStore();

    setToken(null);
    setRoles(null);
  }, [client, setToken]);
};

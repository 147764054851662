import React, { useState } from 'react';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';
import { useLogin } from 'views/auth/hooks';
import BackgroundParticles from '../../BackgroundParticles';
import styles from './SignIn.module.css';

const SignIn = () => {
  const { login, loading, error } = useLogin();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  // TODO Show env
  return (
    <>
      <BackgroundParticles />
      <div className={styles.grid}>
        <Segment
          as={Form}
          stacked
          color="blue"
          error={!!error}
          onSubmit={() => {
            login({
              userName,
              password,
            });
          }}
        >
          <Header as="h2" inverted textAlign="center">
            {process.env.REACT_APP_NAME}
          </Header>
          <Message header="שם משתמש או סיסמא שגויים" error />
          <Form.Input
            fluid
            name="username"
            icon="user"
            iconPosition="left"
            placeholder="שם משתמש"
            onChange={(e) => setUserName(e.target.value)}
          />
          <Form.Input
            fluid
            name="password"
            icon="lock"
            iconPosition="left"
            placeholder="סיסמא"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            fluid
            inverted
            loading={loading}
            color="blue"
            size="large"
            content="התחבר"
          />
        </Segment>
      </div>
    </>
  );
};

export default SignIn;

function validateFirstname(errors, name) {
  // First name can only contains letters in English or Hebrew
  if (!name || !name.match('[a-zA-Zא-ת]+')) {
    errors.push('שם פרטי חייב להיות בעברית או באנגלית');
  }
}

function validateLastname(errors, lastname) {
  // Last name can only contains letters in English or Hebrew
  if (!lastname || !lastname.match('[a-zA-Zא-ת]+')) {
    errors.push('שם משפחה חייב להיות בעברית או באנגלית');
  }
}

function validateIdentity(errors, id) {
  // First we check if the identity is a number
  if (isNaN(id) || id.length !== 9) {
    errors.push('מספר ת.ז. צריך להיות בעל 9 ספרות');
  }
}

function validateAddress(errors, address) {
  if (!address.match('[0-9a-zA-Zא-ת]+')) {
    errors.push('כתובת לא תקין');
  }
}

function validatePhone(errors, phone) {
  const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  if (!phone.match(regex)) {
    errors.push('מספר טלפון אינו תקין. דוגמא למספר טלפון תקין xxx-xxx-xxxx');
  }
}

export function validateClient(client) {
  const {
    firstName, lastName, pid, address, phone, phone2,
  } = client;

  const errors = [];

  validateFirstname(errors, firstName);
  validateLastname(errors, lastName);

  if (pid && pid !== '') {
    validateIdentity(errors, pid);
  }

  if (address && address !== '') {
    validateAddress(errors, address);
  }

  if (phone && phone !== '') {
    validatePhone(errors, phone);
  }

  if (phone2 && phone2 !== '') {
    validatePhone(errors, phone2);
  }

  return errors;
}

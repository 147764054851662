import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLogout } from 'views/auth/hooks/useLogout';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';

import ROUTES from 'utils/routes';

import { useHasRole } from 'views/auth/hooks/useAuth';
import ClientsList from './unknown/ClientsList';
import MenuRoute from './MenuRoute';

const useModal = (defaultState = false) => {
  const [open, setOpen] = useState(defaultState);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  return [open, openModal, closeModal];
};

const DialogLogout = ({ open, onClose }) => {
  const logout = useLogout();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>התנתק</DialogTitle>
      <DialogContent>
        <DialogContentText>
          האם אתה בטוח שבצרונך להתנתק מהמערכת
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          בטל
        </Button>
        <Button onClick={logout} variant="outlined" color="primary">
          התנתק
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

const SidebarMenu = () => {
  const [open, openModal, closeModal] = useModal();

  const hasRole = useHasRole('ADMIN');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <List>
        {ROUTES.map((route) => (
          <MenuRoute key={route.url} {...route} />
        ))}
        <ListItem button onClick={openModal}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="התנתק" />
        </ListItem>
      </List>
      <Box component={Divider} />
      {hasRole && (
        <>
          <ClientsList />
          <Box sx={{ m: 3, borderRadius: 2, border: '1px dashed grey' }}>
            <AdapterLink to="/clients/new">
              <Button color="primary" fullWidth startIcon={<AddIcon />}>
                הוסף לקוח
              </Button>
            </AdapterLink>
          </Box>
        </>
      )}
      <DialogLogout open={open} onClose={closeModal} />
    </Box>
  );
};
export default SidebarMenu;

import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Skeleton } from '@material-ui/core'

const ClientSkeleton = ({ width }) => (
  <ListItem>
    <ListItemText>
      <Skeleton style={{ width }} />
    </ListItemText>
  </ListItem>
);

export default ClientSkeleton;

import { useCallback, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';
import { useQuery } from '@apollo/client';
import { CLIENTS_QUERY } from 'gql/queries';
import ClientSkeleton from './ClientSkeleton';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { NavLink } from 'react-router-dom';

import { FixedSizeList as WindowedList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStylesDot = makeStyles((theme) => ({
  dot: {
    backgroundColor: ({ active }) =>
      active ? theme.palette.success.main : theme.palette.error.main,
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
}));

const ClientDot = ({ active }) => {
  const classes = useStylesDot({ active });

  return <div className={classes.dot} />;
};

const Row = ({ data, index, style }) => {
  const client = data[index];

  return (
    <ListItem
      key={client.id}
      style={style}
      selected={1 === client.id}
      component={NavLink}
      to={`/clients/${client.id}/loans`}
      button
    >
      <ListItemIcon>
        <ClientDot active={client.active} />
      </ListItemIcon>
      <ListItemText>
        {client.firstName} {client.lastName}
      </ListItemText>
    </ListItem>
  );
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'block',
    flexGrow: 1,
  },
  searchItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const ClientsList = () => {
  const [activeOnly, setActiveOnly] = useState(true);
  const [notActiveOnly, setNotActiveOnly] = useState(true);

  const [query, setQuery] = useState('');
  const { loading, error, data } = useQuery(CLIENTS_QUERY);

  const changeQuery = useCallback(({ target: { value } }) => {
    setQuery(value);
  }, []);

  const onChangeActive = ({ target: { checked } }) => {
    setActiveOnly(checked);
  };

  const onChangeNotActive = ({ target: { checked } }) => {
    setNotActiveOnly(checked);
  };

  const clientsWithStatus = useMemo(() => {
    let clients = data?.clients ?? [];

    return clients.map((client) => ({
      ...client,
      active: !!data?.overview?.items?.find((x) => x === client.id),
    }));
  }, [data]);

  const filteredClients = useMemo(() => {
    if (!activeOnly && !notActiveOnly) {
      return [];
    }

    return clientsWithStatus.filter((client) => {
      const activeFilter = !activeOnly ? client.active !== true : true;
      const nonActiveFilter = !notActiveOnly ? client.active !== false : true;
      const queryFilter = query
        ? `${client.firstName.trim()} ${client.lastName.trim()}`.includes(query)
        : true;

      return activeFilter && nonActiveFilter && queryFilter;
    });
  }, [activeOnly, notActiveOnly, query, clientsWithStatus]);

  const history = useHistory();

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (filteredClients.length > 0) {
        const { id } = filteredClients[0];
        history.push(`/clients/${id}/loans`);
      }
    },
    [filteredClients]
  );
  const classes = useStyles();

  return (
    <>
      <Box>
        <ListItem
          className={classes.searchItem}
          component="form"
          onSubmit={handleSubmit}
        >
          <ListItemText>
            <InputBase
              placeholder="חיפוש לקוח"
              value={query}
              onChange={changeQuery}
            />
          </ListItemText>
          <IconButton type="submit" edge="end">
            <SearchIcon />
          </IconButton>
        </ListItem>
        <Divider />
        <ListItem dense>
          <FormControlLabel
            control={
              <Checkbox checked={activeOnly} onChange={onChangeActive} />
            }
            label="הצג לקוחות פעילים"
          />
        </ListItem>
        <ListItem dense>
          <FormControlLabel
            control={
              <Checkbox checked={notActiveOnly} onChange={onChangeNotActive} />
            }
            label="הצג לקוחות לא פעילים"
          />
        </ListItem>
      </Box>
      <List disablePadding className={classes.root}>
        <Divider component="li" />
        {error && <>Error network</>}
        {loading && (
          <>
            <ClientSkeleton width="60%" />
            <ClientSkeleton width="45%" />
            <ClientSkeleton width="75%" />
          </>
        )}
        {/* {clients.length === 0 && <div>EmptyState</div>} */}
        {query && filteredClients.length === 0 && (
          <ListItem>
            <ListItemText>לא נמצא תוצאות</ListItemText>
          </ListItem>
        )}
        <AutoSizer>
          {({ height, width }) => (
            <WindowedList
              height={height}
              itemCount={filteredClients.length}
              itemData={filteredClients}
              itemSize={35}
              width={width}
            >
              {Row}
            </WindowedList>
          )}
        </AutoSizer>
      </List>
      <Box component={Divider} />
      <Box sx={{ m: 3 }}>
        {data?.overview && data?.overview?.items && data?.clients && (
          <Typography>
            {`${data.clients.length} לקוחות`}
            {/* {`, `} */}
            <br />
            {`${data.overview.items.length} לקוחות פעילים`}
            {/* {`, `} */}
            <br />
            {data.overview.count} {'הלוואות פעילות'}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ClientsList;

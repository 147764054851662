import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useAuth } from './index';

const AUTHENTICATE_QUERY = gql`
  mutation Login($input: AuthInput!) {
    login(input: $input) {
      token
      roles
    }
  }
`;

export const useLogin = () => {
  const { setToken, setRoles } = useAuth();

  const [rawLogin, { loading, error }] = useMutation(AUTHENTICATE_QUERY, {
    onCompleted({ login }) {
      const { token, roles } = login;

      setToken(token);
      setRoles(roles);
    },
  });

  const login = useCallback(
    ({ userName, password }) => {
      rawLogin({
        variables: {
          input: {
            userName,
            password,
          },
        },
      });
    },
    [rawLogin],
  );

  return {
    login,
    loading,
    error,
  };
};

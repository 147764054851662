import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Message, Button, Container } from 'semantic-ui-react';
import { validateClient } from 'validators/userValidation';
import { useMutation } from '@apollo/client';
import { ADD_CLIENT } from 'gql/mutations';
import { CLIENTS_QUERY } from 'gql/queries';

const NewClient = () => {
  const [client, setClient] = useState({});
  const [errors, setErrors] = useState([]);

  const history = useHistory();

  const [addClient, { loading, error }] = useMutation(ADD_CLIENT, {
    onCompleted({ addClient }) {
      history.push(`/clients/${addClient.id}`);
    },
    update(cache, { data }) {
      const { clients } = cache.readQuery({
        query: CLIENTS_QUERY,
      });

      // TODO write to paginated results?
      cache.writeQuery({
        query: CLIENTS_QUERY,
        data: {
          clients: [...clients, data.addClient],
        },
      });
    },
  });

  const handleSubmit = () => {
    // If we are already have a server request loading
    if (loading) {
      // In this case, we don't want to do anything
      return;
    }

    // If there is already an error and nothing changed in the form
    if (errors.length > 0) {
      // In this case, we want to shake the message.
      // return;
    }

    const newErrors = validateClient(client);

    setErrors(newErrors);

    // If there is no errors
    if (newErrors.length === 0) {
      // TODO change to NUMBER constructor?
      client.pid = parseInt(client.pid, 10);
      addClient({ variables: { input: { ...client } } });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setClient((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // TODO fix error
  return (
    <Container>
      <Message
        attached
        header="פתיחת כרטיס לקוח"
        content="מלא את כל הפרטים המסומנים בכוכבית בשביל לפתוח כרטיס לקוח חדש"
      />
      <Form
        error={errors.length > 0}
        className="attached segment stacked"
        onSubmit={handleSubmit}
      >
        <Message
          negative
          hidden={!error}
          header="בעיית רשת"
          content={JSON.stringify(error)}
        />
        <Message error header="בעיה בהוספת לקוח" list={errors} />
        <Form.Group widths="equal">
          <Form.Input
            name="pid"
            label="תעודת זהות"
            placeholder="תעודת זהות"
            readOnly={loading}
            onChange={handleInputChange}
          />
          <Form.Input
            name="firstName"
            label="שם פרטי"
            placeholder="שם פרטי"
            required
            readOnly={loading}
            onChange={handleInputChange}
          />
          <Form.Input
            name="lastName"
            label="שם משפחה"
            placeholder="שם משפחה"
            required
            readOnly={loading}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Input
          name="address"
          label="כתובת"
          placeholder="כתובת מגורים"
          fluid
          readOnly={loading}
          onChange={handleInputChange}
        />
        <Form.Group widths="2">
          <Form.Input
            name="phone"
            label="מספר טלפון"
            placeholder="מספר טלפון"
            readOnly={loading}
            onChange={handleInputChange}
          />
          <Form.Input
            name="phone2"
            label="מספר טלפון נוסף"
            placeholder="מספר טלפון נוסף"
            readOnly={loading}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Button type="submit" color="teal" loading={loading} content="שמור" />
      </Form>
    </Container>
  );
};

export default NewClient;
